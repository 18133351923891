import React from 'react';
import readExcelAndGeocode from './readexcel'; // Adjust the path if necessary

const FileUploader = ({ setLocationData }) => {
  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Access the first file from the FileList
    if (file) {
      try {
        console.log('File selected:', file);
        const data = await readExcelAndGeocode(file); // Pass the File object
        console.log('Geocoded Location Data:', data);
  
        setLocationData(data); // Set the geocoded data
  
        console.log("Updated Location Data in App after state update:", data); 
      } catch (error) {
        console.error("Error in file processing:", error);
      }
    } else {
      console.warn('No file selected');
    }
  };
  

  return (
    <div>
      <input 
        type="file" 
        accept=".xlsx, .xls" 
        onChange={handleFileChange} 
      />
    </div>
  );
};

export default FileUploader;
