import * as XLSX from 'xlsx';

// Function to read and process Excel file with latitude and longitude
const readExcelWithCoordinates = (file) => {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets['Master sheet'];

        if (!worksheet) {
          console.error('No worksheet named "Master sheet" found');
          reject(new Error('Worksheet not found'));
          return;
        }

        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const locations = jsonData.map(row => {
          const lat = row["lat"] !== 'NA' ? row["lat"] : null;
          const lng = row["lng"] !== 'NA' ? row["lng"] : null;
          const manufacturer = row["manufacturer"] !== 'NA' ? row["manufacturer"] : '';

          // Only include valid locations with lat/lng
          return { lat, lng, manufacturer };
        }).filter(loc => loc.lat !== null && loc.lng !== null);

        // Prepare data for Excel export, including original addresses and manufacturer
        const outputData = locations.map((location) => ({
          ...location,
        }));

        // Create a new workbook and add the data to it
        const newWorkbook = XLSX.utils.book_new();
        const newWorksheet = XLSX.utils.json_to_sheet(outputData);
        XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, 'Processed Data');

        // Generate a downloadable Excel file
        XLSX.writeFile(newWorkbook, 'Processed_Locations.xlsx');

        resolve(locations);
      } catch (error) {
        console.error("Error reading file or processing data:", error);
        reject(error);
      }
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export default readExcelWithCoordinates;
