import React, { useState, useEffect } from 'react';
import Map from './Map';
import FileUploader from './FileUploader.js';

const predefinedLocations = [
  { lat: 39.8740051, lng: -75.4633113, manufacturer: 'CSC' },
  { lat: 40.6532, lng: -80.0795, manufacturer: 'CSC' },
  { lat: 42.898236, lng: -78.6342, manufacturer: 'CSC' },
  { lat: 35.721268, lng: -77.915543, manufacturer: 'CSC' },
  { lat: 34.0754, lng: -84.2941, manufacturer: 'CSC' },
  { lat: 27.9378, lng: -82.2859, manufacturer: 'CSC' },
  { lat: 32.7767, lng: -96.797, manufacturer: 'CSC' },
  { lat: 29.9988, lng: -95.2622, manufacturer: 'CSC' },
  { lat: 29.9405, lng: -90.2031, manufacturer: 'CSC' },
  { lat: 35.1495, lng: -90.049, manufacturer: 'CSC' },
  { lat: 35.4107, lng: -80.8429, manufacturer: 'CSC' },
  { lat: 36.1627, lng: -86.7816, manufacturer: 'CSC' },
  { lat: 41.4295, lng: -88.2287, manufacturer: 'CSC' },
  { lat: 43.0125, lng: -87.9726, manufacturer: 'CSC' },
  { lat: 39.1031, lng: -84.512, manufacturer: 'CSC' },
  { lat: 40.6532, lng: -80.0795, manufacturer: 'CSC' },
  { lat: 42.5869, lng: -82.9196, manufacturer: 'CSC' },
  { lat: 38.5816, lng: -121.4944, manufacturer: 'CSC' },
  { lat: 33.5387, lng: -112.186, manufacturer: 'CSC' },
  { lat: 40.7608, lng: -111.891, manufacturer: 'CSC' },
  { lat: 45.9046, lng: -122.744, manufacturer: 'CSC' },
  { lat: 29.4252, lng: -98.4946, manufacturer: 'CSC' },
  { lat: 34.0122, lng: -117.6889, manufacturer: 'CSC' },
  { lat: 39.7392, lng: -104.9903, manufacturer: 'CSC' },
  { lat: 35.5006, lng: -97.6114, manufacturer: 'CSC' },
  { lat: 39.2164, lng: -94.4795, manufacturer: 'CSC' },
  { lat: 44.9537, lng: -93.09, manufacturer: 'CSC' },
  { lat: 41.2565, lng: -95.9345, manufacturer: 'CSC' },
  { lat: 41.5236, lng: -90.5776, manufacturer: 'CSC' },
  { lat: 39.1985836, lng: -76.6012646, manufacturer: 'CSC' },
  { lat: 30.5477, lng: -88.1753, manufacturer: 'CSC' },
  { lat: 33.3943, lng: -104.523, manufacturer: 'CSC' },
  { lat: 30.24, lng: -81.3853, manufacturer: 'CSC' },
  { lat: 38.627, lng: -88.9456, manufacturer: 'CSC' },
  { lat: 39.7392, lng: -104.9903, manufacturer: 'CSC' },
  { lat: 42, lng: -71.9903, manufacturer: 'CSC' },
];



const predefinedLocations2 = [
  { lat: 42.8899, lng: -106.5209 },
  { lat: 33.8211, lng: -86.8023 },
  { lat: 41.9967, lng: -87.6859 },
  { lat: 38.3290, lng: -104.7366 },
  { lat: 41.3315, lng: -88.8381 },
  { lat: 39.6005, lng: -94.6816 },
  { lat: 35.1022, lng: -82.0808 },
  { lat: 39.8702, lng: -75.3817 },
  { lat: 42.6265, lng: -83.0657 },
  { lat: 37.8497, lng: -90.5129 },
  { lat: 39.2930, lng: -93.9733 },
  { lat: 30.2239, lng: -91.8127 },
  { lat: 45.0731, lng: -93.1583 },
  { lat: 39.4125, lng: -86.0608 },
  { lat: 39.7885, lng: -75.5259 },
  { lat: 41.3573, lng: -88.4212 },
  { lat: 33.9674, lng: -117.4096 },
  { lat: 42.7070, lng: -87.8023 },
  { lat: 32.4543, lng: -94.6722 },
  { lat: 45.1662, lng: -93.2852 },
  { lat: 35.5592, lng: -76.9625 },
  { lat: 38.5056, lng: -121.5399 },
  { lat: 42.6938, lng: -82.5146 },
  { lat: 38.6457, lng: -121.4510 },
  { lat: 30.2695, lng: -90.9590 },
  { lat: 32.8903, lng: -97.3920 },
  { lat: 35.5346, lng: -97.9440 },
  { lat: 40.7408, lng: -84.1624 },
  { lat: 46.1558, lng: -119.0809 },
  { lat: 40.8674, lng: -79.8118 },
  { lat: 40.4447, lng: -99.3695 },
  { lat: 30.1018, lng: -94.1097 },
  { lat: 33.8130, lng: -86.7458 },
  { lat: 30.1790, lng: -81.7244 },
  { lat: 41.5393, lng: -90.5946 },
  { lat: 33.8820, lng: -84.0826 },
  { lat: 41.3813, lng: -89.4674 },
  { lat: 42.0522, lng: -97.4387 },
  { lat: 31.8341, lng: -97.0857 },
  { lat: 41.3884, lng: -71.4789 },
  { lat: 38.6913, lng: -121.3632 },
  { lat: 39.0935, lng: -84.7111 },
  { lat: 34.2476, lng: -117.2380 },
  { lat: 28.2727, lng: -82.3552 },
  { lat: 41.1231, lng: -79.8942 },
  { lat: 40.8712, lng: -76.3464 },
  { lat: 42.1511, lng: -80.0326 },
  { lat: 37.7060, lng: -90.8070 },
  { lat: 38.3003, lng: -104.7505 },
  { lat: 45.9246, lng: -122.7529 },
  { lat: 34.4051, lng: -117.3643 },
  { lat: 39.7346, lng: -105.0904 },
  { lat: 33.7007, lng: -112.4058 },
  { lat: 29.9428, lng: -95.6231 },
  { lat: 41.7915, lng: -84.0431 },
  { lat: 30.1662, lng: -95.2054 },
  { lat: 41.8540, lng: -71.4657 },
  { lat: 39.2011, lng: -121.1964 },
  { lat: 41.3348, lng: -88.4024 },
  { lat: 38.0881, lng: -99.8994 },
  { lat: 35.7318, lng: -97.3901 },
  { lat: 35.6073, lng: -96.6602 },
  { lat: 35.9257, lng: -77.5756 },
  { lat: 38.0854, lng: -99.8994 },
  { lat: 31.7743, lng: -106.2583 },
  { lat: 33.8015, lng: -84.0873 },
  { lat: 32.9026, lng: -105.9372 },
  { lat: 39.0601, lng: -85.6970 },
  { lat: 30.2872, lng: -89.3932 },
  { lat: 30.9953, lng: -88.2912 },
  { lat: 45.7168, lng: -122.5544 },
  { lat: 42.5869, lng: -114.7513 },
  { lat: 35.2477, lng: -89.1587 },
  { lat: 30.6602, lng: -89.6065 },
  { lat: 45.6636, lng: -122.6034 },
  { lat: 41.5801, lng: -90.6749 },
  { lat: 45.6556, lng: -122.6216 },
  { lat: 31.9299, lng: -106.3995 },
  { lat: 35.1816, lng: -101.7930 },
  { lat: 29.8478, lng: -95.2863 },
  { lat: 35.9114, lng: -86.4147 },
  { lat: 44.2879, lng: -105.4728 },
  { lat: 30.5718, lng: -81.8318 },
  { lat: 31.3056, lng: -89.3393 },
  { lat: 36.5084, lng: -86.7864 },
  { lat: 33.9674, lng: -117.4096 },
  { lat: 34.7315, lng: -82.2467 },
  { lat: 29.6246, lng: -90.4976 },
  { lat: 31.8181, lng: -106.5322 },
  { lat: 31.8003, lng: -97.0856 },
  { lat: 40.6263, lng: -75.3762 },
  { lat: 33.4017, lng: -111.5924 },
  { lat: 35.5646, lng: -77.4732 },
  { lat: 33.4297, lng: -84.0117 },
  { lat: 41.8841, lng: -90.1900 },
  { lat: 31.7988, lng: -97.0979 },
  { lat: 40.9842, lng: -79.8744 },
  { lat: 29.4004, lng: -98.7148 },
  { lat: 33.6918, lng: -117.1262 },
  { lat: 34.2372, lng: -117.2753 },
  { lat: 35.1837, lng: -77.5259 },
  { lat: 34.6875, lng: -80.7835 },
  { lat: 40.6776, lng: -79.6921 },
  { lat: 41.8693, lng: -71.4154 },
  { lat: 39.6292, lng: -104.7984 },
  { lat: 35.0816, lng: -89.8167 },
  { lat: 34.9145, lng: -120.4415 },
  { lat: 39.2981, lng: -85.7721 },
  { lat: 37.0921, lng: -77.2369 },
  { lat: 35.5387, lng: -97.8317 },
  { lat: 39.7905, lng: -94.8189 },
  { lat: 39.6561, lng: -86.2626 },
  { lat: 32.3238, lng: -92.1774 },
  { lat: 41.7180, lng: -92.8250 },
  { lat: 46.8261, lng: -114.0384 },
  { lat: 36.4272, lng: -86.6989 },
  { lat: 44.0253, lng: -73.0968 },
  { lat: 43.0693, lng: -92.6590 },
  { lat: 41.1442, lng: -96.1269 },
  { lat: 40.3689, lng: -111.7773 },
  { lat: 29.1310, lng: -98.6289 },
  { lat: 38.5232, lng: -88.9447 },
  { lat: 35.9049, lng: -86.3582 },
  { lat: 38.0814, lng: -78.8934 },
  { lat: 38.2375, lng: -75.4465 },
  { lat: 42.7233, lng: -82.7821 },
  { lat: 44.0777, lng: -93.2321 },
  { lat: 35.8291, lng: -86.6309 },
  { lat: 40.6885, lng: -75.4532 },
  { lat: 45.9413, lng: -90.4127 },
  { lat: 33.8969, lng: -81.0666 },
  { lat: 39.9095, lng: -93.9563 },
  { lat: 30.0217, lng: -95.6488 },
  { lat: 38.6016, lng: -75.6979 },
  { lat: 28.1173, lng: -81.9250 },
  { lat: 34.0235, lng: -117.5702 },
  { lat: 42.0749, lng: -97.3351 },
  { lat: 33.2341, lng: -84.8447 },
  { lat: 43.4353, lng: -70.7826 },
  { lat: 30.4150, lng: -88.2439 },
  { lat: 32.6156, lng: -97.1213 },
  { lat: 35.2978, lng: -89.3213 },
  { lat: 41.0552, lng: -80.6522 },
  { lat: 36.0653, lng: -96.1007 },
  { lat: 26.2146, lng: -97.6997 },
  { lat: 38.3973, lng: -75.5530 },
  { lat: 43.0475, lng: -74.8648 },
  { lat: 33.8495, lng: -118.1839 },
  { lat: 41.4365, lng: -88.2113 },
  { lat: 41.8668, lng: -83.7060 },
  { lat: 42.8767, lng: -78.5913 },
  { lat: 35.9573, lng: -86.4693 },
  { lat: 40.6419, lng: -111.9915 },
  { lat: 33.1836, lng: -94.1724 },
  { lat: 39.1917, lng: -94.4869 },
  { lat: 42.0667, lng: -71.3154 },
  { lat: 37.6139, lng: -122.0378 },
  { lat: 31.3451, lng: -89.1855 },
  { lat: 33.9996, lng: -117.8399 },
  { lat: 38.9959, lng: -84.8893 },
  { lat: 39.3457, lng: -76.5035 },
  { lat: 44.9339, lng: -93.2113 },
  { lat: 35.9101, lng: -86.5073 },
  { lat: 41.5586, lng: -90.5064 },
  { lat: 42.1415, lng: -80.0030 },
  { lat: 42.8090, lng: -88.2166 },
  { lat: 41.1080, lng: -85.1096 },
  { lat: 36.0441, lng: -86.5409 },

];

const App = () => {
  const [locationData, setLocationData] = useState([]);

  const combinedLocations = [
    ...predefinedLocations,
    ...predefinedLocations2,
    ...locationData,
  ];

  useEffect(() => {
    console.log("Uploaded Location Data:", locationData);
    console.log("Combined Locations:", combinedLocations);
  }, [locationData, combinedLocations]);

  return (
    <div>
      <h1>USA Map</h1>
      <FileUploader setLocationData={setLocationData} />
      <Map locations={combinedLocations} />
    </div>
  );
};




export default App;
