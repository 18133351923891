import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { MarkerClusterer } from '@googlemaps/markerclusterer'; // Import MarkerClusterer

const Map = ({ locations }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const center = { lat: 37.0902, lng: -95.7129 }; // Center of USA
  const zoom = 4;
  const [isLoaded, setIsLoaded] = useState(false);
  const mapRef = useRef(null); // Ref to access the Google Map instance

  // Google Maps API loading check
  useEffect(() => {
    const checkGoogleMapsLoaded = () => {
      if (window.google) {
        setIsLoaded(true);
        console.log("Google Maps API has loaded");
      }
    };

    // Check if google maps API is loaded initially
    checkGoogleMapsLoaded();

    const interval = setInterval(checkGoogleMapsLoaded, 1000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Function to generate marker color based on manufacturer
  const getMarkerIcon = (manufacturer) => {
    switch (manufacturer) {
      case 'CSC':
        return 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'; // red for predefined (CSC)
      case undefined:
        return 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'; // green for additional dispatch
      case 'FAIRBANKS':
        return 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'; // Blue for FAIRBANKS
      default:
        return 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png'; // Yellow for others
    }
  };

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      const map = mapRef.current;
      const markers = locations
        .filter((location) => location.lat && location.lng) // Filter out locations with missing lat/lng
        .map((location) => {
          const position = { lat: location.lat, lng: location.lng };
          const icon = getMarkerIcon(location.manufacturer);
          return new window.google.maps.Marker({
            position,
            icon,
          });
        });

      // Initialize MarkerClusterer after markers are created
      new MarkerClusterer({ map, markers });
    }
  }, [isLoaded, locations]);

  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={{ height: "100vh", width: "100%" }}
        zoom={zoom}
        center={center}
        onLoad={(map) => (mapRef.current = map)} // Save map instance for later use
      >
        {isLoaded && (
          <>
            {/* Render the markers with MarkerClusterer */}
            {locations
              .filter((location) => location.lat && location.lng) // Filter out locations with missing lat/lng
              .map((location, index) => {
                const position = { lat: location.lat, lng: location.lng };
                return (
                  <Marker
                    key={index}
                    position={position}
                    icon={getMarkerIcon(location.manufacturer)} // Apply dynamic icon based on manufacturer
                  />
                );
              })}
          </>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
